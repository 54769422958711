import {currentUser, updateUserProfile} from '../database/user';

export default {
  namespaced: true,
  state: {
    profile: null
  },
  mutations: {
    setProfile(state, profile) {
      state.profile = profile;
    }
  },
  actions: {
    user: () => new Promise((res) => {
      res(currentUser())
    }),
    getCurrentProfile: async ({commit}) => {
      const user = await currentUser();
      commit('setProfile', user);
    },
    updateProfile: ({state, commit}, userProfile) => new Promise((res, rej) => {
      updateUserProfile(userProfile).then(() => {
        commit('setProfile', {...state.profile, ...userProfile});
        res()
      }).catch(rej)
    })
  }
}