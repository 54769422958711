<template>
  <div class="origin fll--viewport">
    <div class="abs--center-center">
      <form v-if="reEnterEmail" @submit.stop.prevent="handleSubmit">
        <h2>
          Please re-enter your email
        </h2>
        <div class="flx typ--ii ofst--top-sm">
          <InputText v-model="email" @ready="handleInputReady">Email address</InputText>
          <button type="submit" class="button--solid rst--button flt--right" :disabled="button_disabled">Sign in</button>
        </div>
      </form>
      <p v-if="error || success" v-html="success || error"></p>
      <p v-if="!loading">
        {{message}}
      </p>
      <Loader v-else width="4rem" height="4rem" />
    </div>
  </div>
</template>

<script>
import Loader from '@/components/parts/loader'
import InputText from '../components/parts/input-text';
import {minimumTimeout} from '@/lib/minimumTimeout'
import {isValidEmail} from '../lib/validateEmail'



export default {
  components: {InputText, Loader},
  data: () => ({
    message: 'Confirming email...',
    loading: false,
    error: false,
    success: false,
    reEnterEmail: false,
    email: ''
  }),
  computed: {
      button_disabled() {
          return isValidEmail(this.email);
      }
  },
  mounted() {
    const timeout = minimumTimeout();
    const next = (name = 'App') => setTimeout(() => this.$router.push({name}), 750)
    this.loading = true;
    this.$store.dispatch('auth/checkSignedIn').then(() => {
      timeout().then(() => {
        this.loading = false;
        next()
      })
    }).catch(() => {
      this.$store.dispatch('auth/confirm', {href: window.location.href}).then(() => {
        this.message = 'Success! Signing in'
        timeout().then(() => {
          this.loading = false;
          next()
        })
      }).catch((err) => {
        if (err === 'no_email') {
          this.reEnterEmail = true;
          this.loading = false
          this.message = null
        } else {
          timeout().then(() => {
            this.message = 'There was an error, returning to sign up page.'
            this.loading = false;
            next('Signin');
          })
        }
      })
    })
  },
  methods: {
    handleInputReady({focus}) {
      focus()
    },
    handleSubmit () {
      const timeout = minimumTimeout();
      const next = (name = 'App') => setTimeout(() => this.$router.push({name}), 750)
      this.loading = true;
      this.reEnterEmail = false;

      this.$store.dispatch('auth/confirm', {href: window.location.href, email: this.email}).then(() => {
        this.message = 'Success! Signing in'
        timeout().then(() => {
          this.loading = false;
          next()
        })
      }).catch((err) => {
        if (err === 'no_email') {
          this.reEnterEmail = true;
          this.loading = false
          this.message = null
        } else {
          timeout().then(() => {
            this.message = 'There was an error, returning to sign up page.'
            this.loading = false;
            next('Signin');
          })
        }
      })
    }
  }
}
</script>

<style>

</style>