import {createLink} from '../database/links';
import {getIdToken} from '../database/auth';
import cloneDeep from 'lodash/cloneDeep';
import link from '../models/link';

const url = location.hostname === 'localhost' ? 'http://localhost:5001/ux-flow-builder/us-central1/api' : ' https://us-central1-ux-flow-builder.cloudfunctions.net/api'

export default {
  namespaced: true,
  state: {
    share: '',
    survey: ''
  },
  getters: {},
  mutations: {
    setShare(state, share) {
      state.share = share;
    },
    setSurvey(state, survey) {
      state.survey = survey
    }
  },
  actions: {
    createSecureLink: async ({commit, rootState}, uid) => {
      const date = new Date();
      const newLink = cloneDeep(link);
      newLink.owner = rootState.auth.uid;
      newLink.expires = date.setDate(date.getDate() + 30);
      newLink.ref = uid;
      const docRef = await createLink(newLink);
      const href = location.origin + '/app/join/' + docRef.id;
      commit('setShare', href);
      return docRef.id;
    },
    createPublicLink: async ({commit, rootState}, uid) => {
      const date = new Date();
      const newLink = cloneDeep(link);
      newLink.owner = rootState.auth.uid;
      newLink.secure = false;
      newLink.expires = date.setDate(date.getDate() + 30);
      newLink.ref = uid;
      const docRef = await createLink(newLink);
      const href = location.origin + '/app/view/' + docRef.id;
      commit('setShare', href);
      return docRef.id;
    },
    createSurvey: async ({commit, rootState}, uid) => {
      const date = new Date();
      const newLink = cloneDeep(link);
      newLink.owner = rootState.auth.uid;
      newLink.expires = date.setDate(date.getDate() + 30);
      newLink.ref = uid;
      const docRef = await createLink(newLink);
      const href = location.origin + '/app/survey/' + docRef.id;
      commit('setSurvey', href);
      return docRef.id;
    },
    getSecureLink: async (_, uid) => {
      const token = await getIdToken();
      const res = await fetch(url + '/app/shared/' + uid, {
        method: "GET",
        headers: new Headers({
          'Authorization': `Bearer ${token}`, 
        })
      }).catch((err) => console.error(err))
      const {ref} = await res.json();
      return ref ? ref : ''
    },
    getSurvey: async (_, uid) => {
      try {
        const res = await fetch(url + '/survey/' + uid, {method: "GET",})
        const flow = await res.json();
        return flow
      } catch (err) {
        console.error(err)
      }
    },
    getSharedFlow: async (_, uid) => {
      try {
        const res = await fetch(url + '/public/shared/' + uid, {method: "GET",});
        const flow = await res.json();
        return flow
      } catch (err) {
        return err
      }
    }
  }
}
