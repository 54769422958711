const uids = [];

/**
 * Generates unique Uids
 * @param len
 * @returns {string}
 */

export function generateUid (len) {
    len = len || 7;
    return (function check(uids) {
      let uid = `_${Math.random().toString(35).substr(2, len)}`
      if (uids.indexOf(uid) > -1) {
        return check(uids)
      }
      uids.push(uid)
      return uid
    })(uids);
  }