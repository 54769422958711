import {firestore} from './firebase';
import { doc, collection, setDoc, writeBatch, getDocs, deleteDoc } from 'firebase/firestore';
import {parseDocs} from '@/lib/parse-docs';

const flows = collection(firestore, "flows");

export const createNode = async (parentUid, uid, data) => await setDoc(doc(flows, parentUid, 'nodes', uid), data);
export const updateNodes = async (flowUid, data) => {
  const batch = writeBatch(firestore);
  const flow = doc(flows, flowUid);
  for (let uid in data) {
    let node = data[uid];
    let nodeRef = doc(flow, 'nodes', uid);
    batch.set(nodeRef, node)
  }
  return await batch.commit()
}
export const getNode = async (parentUid, childUid) => await doc(flows, parentUid, 'nodes', childUid);
export const getAllNodes = async (parentUid) => {
  try {
    const nodes = await getDocs(collection(flows, parentUid, 'nodes'));
    return parseDocs(nodes.docs)
  } catch (err) {
    console.error(err)
    return 
  }
}
export const updateNode = async (parentUid, childUid, data) => {
  try {
    return await setDoc(doc(flows, parentUid, 'nodes', childUid), data)
  } catch (err) {
    console.error(err)
    return err
  }
};
export const deleteNode = async (parentUid, childUid) => {
  try {
    const node = doc(flows, parentUid, 'nodes', childUid);
    deleteDoc(node)
  } catch (err) {
    console.error(err)
    return err
  }
};
export const deleteNodes = async (flowUid, nodes) => {
  try {
    const batch = writeBatch(firestore);
    for(let uid of nodes) {
      batch.delete(doc(flows, flowUid, 'nodes', uid));
    }
    return await batch.commit()
  } catch (err) {
    console.error(err)
    return err;
  }
}