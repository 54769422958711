import {getIdToken} from '@/database/auth';

const url = location.hostname === 'localhost' ? 'http://localhost:5001/ux-flow-builder/us-central1/api' : ' https://us-central1-ux-flow-builder.cloudfunctions.net/api'

export const createSupportTicket = async (ticket) => {
  const token = await getIdToken();
  const res = await fetch(`${url}/app/support`, {
    method: "POST",
    headers: new Headers({
      'Authorization': `Bearer ${token}`, 
    }),
    body: JSON.stringify(ticket)
  }).catch((err) => {
    throw err
  });
  const {success} = await res.json();
  if (success) {
    return success
  }
  throw 'error'
}