import {createWebHistory, createWebHashHistory, createRouter } from "vue-router";
import {routerGuard} from './routerGuard'
import SignIn from '@/views/SignIn'
import Confirm from '@/views/Confirm'

const routes = [
  {
    path: "/signup",
    name: "Signup",
    component: SignIn,
    props: {
      greeting: 'Sign up',
      label: 'Sign up with your email address',
      button: 'Sign up',
      altRoute: '/signin',
      question: 'Already have an account?',
      switch_to: 'Sign in'
    }
  },
  {
    path: "/signin",
    name: "Signin",
    component: SignIn,
    props: {
      greeting: 'Sign in',
      label: 'Sign in with your email address',
      button: 'Sign in',
      altRoute: '/signup',
      question: `Don't have an account yet?`,
      switch_to: 'Sign up'
    }
  },
  {
    path: "/confirm",
    name: "Confirm",
    component: Confirm
  },
  {
    path: "/:uid?",
    name: "App",
    component: () => import('../views/App.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/survey/:uid?",
    name: "Survey",
    component: () => import('../views/Survey.vue')
  },
  {
    path: "/join/:uid?",
    name: "Join",
    component: () => import('../views/Join.vue')
  },
  {
    path: "/demo",
    name: "Demo",
    component: () => import('../views/Demo.vue')
  },
  {
    path: "/view/:uid",
    name: "View",
    component: () => import('../views/View.vue')
  },
];

// ,

const router = createRouter({
  history: process.env.IS_ELECTRON ?  createWebHashHistory('/app/') : createWebHistory('/app/'),
  routes,
});

router.beforeEach(routerGuard)

export default router;
