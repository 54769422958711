import ls from 'local-storage';

export const getLocalStorage = async (key) => {
  const data = ls(key);
  if (data) {
    return JSON.parse(data);
  } 
  throw 'Does not exist'
};

export const setLocalStorage = async (key, data) => ls.set(key, JSON.stringify(data));

export const removeLocalStorage = async (key) => ls.remove(key)

export const clearAllLocalStorage = async () => ls.clear()