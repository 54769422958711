import { createSupportTicket } from "@/apis/support"

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createTicket: (_, ticket) => createSupportTicket(ticket)
  }
}