export function isOver(rect, x, y) {
  return x > rect.x && x < rect.x + rect.width && y > rect.y && y < rect.y + rect.height
}

export function getBoundaries(selectedRects) {
  return selectedRects && selectedRects.length > 0
    ? selectedRects.reduce((boundaries, { top, left, bottom, right }, i) => {
        if (i === 0) {
          return { top, left, bottom, right };
        }
        return {
          top: boundaries.top > top ? top : boundaries.top,
          left: boundaries.left > left ? left : boundaries.left,
          bottom: boundaries.bottom < bottom ? bottom : boundaries.bottom,
          right: boundaries.right < right ? right : boundaries.right,
        };
      }, {})
    : {};
}