import 'thin.css'
import '@/assets/main.scss'
import { createApp, h, resolveComponent } from 'vue';
import store from './store/app';
import router from './router/app';

console.log('App copyright AJM')

const app = createApp({render: () => h(resolveComponent('router-view'))});
app.use(store);
app.use(router);
app.mount('#app');