import { createStore } from 'vuex'
import auth from './auth';
import flow from './flow'
import link from './link';
import user from './user'
import room from './room';
import support from './support';

const store = createStore({
  modules: {auth, flow, link, room, support, user},
  state: {
    cursorState: 'default',
    mouseStart: {},
    mouseLastFrame: {},
    mouseCurrent: {},
    mouseOver: null,
    selected: [],
    selectionArea: {
      x: null,
      y: null,
      width: null,
      height: null
    },
    selectionLayer: null,
    key: null,
    offset: {
      top: 0,
      left: 0
    },
    scroll: {
      top: 0,
      left: 0
    }
  },
  getters: {
    selectedUids (state) {
      return state.selected.map(({uid}) => uid)
    }
  },
  mutations: {
    setCursorState(state, cursorState) {
      state.cursorState = cursorState;
    },
    setMouseCurrent(state, mouseCurrent) {
      state.mouseCurrent = mouseCurrent
    },
    setMouseLastFrame(state, mouseLastFrame) {
      state.mouseLastFrame = mouseLastFrame
    },
    setMouseStart(state, mouseStart) {
      state.mouseStart = mouseStart
    },
    setMouseOver (state, uid) {
      state.mouseOver = uid;
    },
    setSelected(state, selected) {
      state.selected = selected
    },
    setOffset (state, offset) {
      state.offset = offset;
    },
    setScroll(state, scroll) {
      state.scroll = scroll
    },
    setKey (state, key) {
      state.key = key;
    },
    setSelectionArea(state, selectionArea) {
      state.selectionArea = selectionArea;
    },
    setSelectionLayer(state, selectionLayer) {
      state.selectionLayer = selectionLayer
    }
  },
  actions: {
    mouseDown ({commit}, {x,y}) {
      commit('setMouseStart', {x,y});
      commit('setMouseCurrent', {x,y});
      commit('setMouseLastFrame', {x,y})
    },
    mouseMove ({commit, state}, {x,y}) {
      commit('setMouseLastFrame', state.mouseCurrent);
      commit('setMouseCurrent', {x,y});
    },
    mouseUp ({commit, state}, {x,y}) {
      commit('setMouseLastFrame', state.mouseCurrent);
      commit('setMouseCurrent', {x,y});
    }
  }
});

export default store;