import {createRoom} from '../database/room';
import {socket} from '../socket';
import cloneDeep from 'lodash/cloneDeep'
import room from '../models/room';
import {generateUid} from '../lib/generate-uid'

export default {
  namespaced: true,
  state: {
    roomUid: null,
    user: null,
    participants: {},
  },
  getters: {},
  mutations: {
    setRoomUid (state, uid) {
      state.roomUid = uid;
    },
    setUser(state, uid) {
      state.user = uid;
    },
    setParticipants(state, {uid, data}) {
      state.participants = {...state.participants, ...{[uid]: data}};
    }
  },
  actions: {
    createRoom: ({commit, rootState}, ) => new Promise(res => {
      const newRoom = cloneDeep(room);
      const user = generateUid();
      newRoom.owner = rootState.auth.uid;
      newRoom.created = Date.now();
      commit('setUser', user);
      createRoom(newRoom).then(() => {
        const id = 'QsGVE6K8GRTusZyWNXnZ'
        socket.open();
        socket.emit('create room', id);
        commit('setRoomUid', id);
        socket.on('user joined', (uid) => {
          commit('setParticipants', {uid})
        });
        socket.on('mouse move', (data) => {
          commit('setParticipants', data);
       });
      });
      res()
    }),
    joinRoom: ({commit}, id) => new Promise(() => {
      const user = generateUid()
      commit('setRoomUid', id);
      commit('setUser', user)
      socket.open();
      socket.emit('join room' , {id, user});
      socket.on('mouse move', (data) => {
        commit('setParticipants', data);
     });
    }),
    roomMouseMove: ({state}, {x,y}) => new Promise(() => {
      const data = {
        uid: state.user,
        data: {x,y}
      };
      socket.emit('mouse move', data)
    })
  }
}