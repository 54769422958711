<template>
  <div class="signin fll--viewport origin">
    <div class="abs--top-left abs--top-right pad--top-lg draggable"></div>
    <div class="abs--center-center flt--clearfix">
      <form v-if="!success && !error && !loading" @submit.stop.prevent="handleSubmit">
        <h2>
          {{greeting}}
        </h2>
        <div class="flx typ--ii ofst--top-md">
          <InputText v-model="email" @ready="handleInputReady">Email address</InputText>
          <button type="submit" class="button--solid rst--button flt--right" :disabled="button_disabled">{{button}}</button>
        </div>
        <p class="typ--center typ--i ofst--top-sm ofst--top-lg">
          {{question}} <router-link :to="altRoute">{{switch_to}}</router-link>
        </p>
      </form>
      <p v-if="error || success" v-html="success || error"></p>
    </div>
    <Loader v-if="loading" width="4rem" height="4rem" class="abs--center-center" />
  </div>
</template>

<script>
import InputText from '../components/parts/input-text';
import Loader from '@/components/parts/loader'
import {minimumTimeout} from '@/lib/minimumTimeout'
import {isValidEmail} from '../lib/validateEmail'

export default {
  components: {InputText, Loader},
  props: {
    greeting: String,
    label: String,
    button: String,
    altRoute: String,
    switch_to: String,
    question: String
  },
  data: () => ({
    success: null,
    error: null,
    email: '',
    loading: true
  }),
  computed: {
      button_disabled() {
          return isValidEmail(this.email);
      }
  },
  watch: {
    '$route' () {
      this.loading = true;
      const timeout = minimumTimeout();
      timeout().then(() => {
        this.loading = false;
      })
    }
  },
  mounted () {
    const timeout = minimumTimeout();
    this.$store.dispatch('auth/checkSignedIn').then(() => {
      timeout().then(() => {
        this.$router.push('app')
      })
    }).catch(() => {
      timeout().then(() => {
        this.loading = false
      })
    })
  },
  methods: {
    handleInputReady: ({focus}) => setTimeout(focus, 500),
    handleSubmit () {
      this.loading = true;
      const timeout = minimumTimeout();
      this.$store.dispatch('auth/signin', {email: this.email}).then(() => {
        timeout().then(() => {
          this.loading = false;
          this.success = `Please check '${this.email}' for a confirmation link`
        })
      }).catch(() => {
        this.loading = false;
        this.error = `Sorry, there was an error, please try again.`
      })
    }
  }
}
</script>

<style lang="postcss" scoped>

/* 1A9E7C 49C8DB E8DD73 F56F37 DE4B4D */

</style>