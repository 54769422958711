import {firestore} from './firebase';
import { doc, collection, addDoc, getDoc, getDocs, updateDoc, query, where, deleteDoc } from 'firebase/firestore';
import {parseDocs} from '@/lib/parse-docs';

const flows = collection(firestore, "flows");

export const createFlow = async (data) => await addDoc(flows, data);
export const getFlow = (uid) => getDoc(collection, uid);
export const getAllFlows = async (uid) => {
  try {
    const queryUser = query(flows, where('owner', '==', uid));
    const queryShare = query(flows, where('collaborators', 'array-contains', uid));
    const [userFlows, sharedFlows] = await Promise.all([getDocs(queryUser), getDocs(queryShare)])
    return {...parseDocs(sharedFlows.docs), ...parseDocs(userFlows.docs)};
  } catch (err) {
    console.error(err)
    return err
  }
};
export const updateFlow = (uid, data) => {
  const docRef = doc(flows, uid);
  return updateDoc(docRef, data)
}
export const deleteFlow = (uid) => deleteDoc(flows, doc(flows, uid));
