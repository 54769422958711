import Storeux from 'storeux';
import cloneDeep from 'lodash/cloneDeep';
import {createFlow, getAllFlows} from '../database/flows';
import {createNode, updateNodes, updateNode, deleteNodes, getAllNodes} from '../database/nodes';
import flow from '@/models/flow.json'
import event from '@/models/event.json';
import {generateUid} from '@/lib/generate-uid';
import node from '@/models/node.json'
import { isOver } from '@/lib/hit-box';
import first_node from '@/models/first_node.json';
import {exportToJsonFile} from '@/lib/exportToJsonFile';
import {getLocalStorage, setLocalStorage} from '@/localStorage'

const flowStore = new Storeux();

export default {
  namespaced: true,
  state: {
    flowUid: null,
    allFlows: null,
    rootEl: null,
    nodes: null,
    nodeRects: [],
    history: [],
    future: [],
    newNodeUid: null,
    prevNodeUid: null,
    prevEventKey: null,
    tabs: [],
    editingNodeUid: null,
    demo: false,
    initialized: false,
    nodeCache: {}
  },
  getters: {
    flow (state) {
      return state.allFlows && state.flowUid && state.flowUid in state.allFlows ? state.allFlows[state.flowUid] : {}
    },
    readOnly (state, getters, rootState) {
      return state.demo ? false : rootState.auth.uid && getters.flow.owner ? rootState.auth.uid !== getters.flow.owner : true
    },
    nodeCount (state) {
      return Object.keys(state.nodes).length + 1;
    },
    notSelectedRects(state, _, __, rootGetters) {
      return state.nodeRects.filter(rect => !rootGetters.selectedUids.includes(rect.uid));
    },
    isOverUid (_, getters, rootState) {
      const {x, y} = rootState.mouseCurrent;
      const overNodes = getters.notSelectedRects.filter((rect) => isOver(rect, x, y));
      if (overNodes.length === 0) {
        return null
      } else {
        const {uid} = overNodes[0];
        return uid
      }
    },
    hasHistory (state) {
      return state.history.length > 0
    },
    hasFuture (state) {
      return state.future.length > 0
    }
  },
  mutations: {
    addRecentFlow (state, uid) {
      let {tabs} = state;
      const index = tabs.indexOf(uid);
      if (index === -1) {
        tabs.push(uid)
        state.tabs = tabs;
      }
    },
    removeRecentFlow (state, uid) {
      let {tabs} = state;
      const index = tabs.indexOf(uid);
      if (index > -1) {
        if (state.flowUid === uid) {
          if (index === 0) {
            state.flowUid = tabs[1]
          } else {
            state.flowUid = tabs[index - 1];
          }
        }
        tabs.splice(index, 1);
        state.tabs = tabs;
      }
    },
    setDemo (state, demo) {
      state.demo = demo
    },
    setAllFlows(state, flows) {
      state.allFlows = flows  
    },
    setNodes (state, nodes) {
      state.nodes = nodes
    },
    setNewNodeUid (state, nodes) {
      state.newNodeUid = nodes
    },
    setRootEl(state, el) {
      state.rootEl = el;
    },
    setFlow(state, {uid, flow}) {
      state.allFlows = {...state.allFlows, ...{[uid]: flow}}
    },
    setFlowUid(state, uid) {
      state.flowUid = uid
    },
    setEditingNodeUid(state, uid) {
      state.editingNodeUid = uid
    },
    setNodeRects(state, hitBoxes) {
      state.nodeRects = hitBoxes;
    },
    setNodeCache(state, {uid, nodes}) {
      state.nodeCache = {...state.nodeCache, ...{[uid]: nodes}}
    },
    pushHistory (state) {
      while(state.future.length) {
        state.future.shift();
      }

      const old = cloneDeep(state.nodes);

      if (state.history >= 10) {
        state.history.shift();
      }

      state.history.push(old);
    },
    clearHistory (state) {
      while(state.history.length) {
        state.history.shift()
      }
    },
    pushFuture (state, nodes) {
      state.future.push(nodes)
    },
    clearFuture (state) {
      while(state.future.length) {
        state.history.shift()
      }
    },
  },
  actions: {
    undo: ({state}) => {
      if(state.history.length) {
        const present = flowStore.get();
        state.future.push(present);

        const last = cloneDeep(state.history.pop());

        if (last) {
          flowStore.set('*', last);
        }
      }
    },
    redo: ({state}) => {
      if (state.future.length) {
        const present = flowStore.get();
        state.history.push(present);
        const next = cloneDeep(state.future.pop());

        if (next) {
          flowStore.set('*', next);
        }
      }
    },
    saveToDesktop: ({getters, state}) => new Promise((res) => {
      const nodes = flowStore.get();
      const uid = state.flowUid;
      const {flow} = getters;
      const data = {uid, flow, nodes}
      exportToJsonFile(data, flow.name + '.floux');
      res()
    }),
    openLocal: ({state, commit}, nextUid) => new Promise((res, rej) => {
      const localNodes = getLocalStorage(nextUid);
      if (localNodes) {
        commit('clearHistory');
        commit('clearFuture');
        const uid = state.flowUid;
        const nodes = flowStore.get();
        setLocalStorage(uid, nodes)
        flowStore.set('*', localNodes);
        res();
      } else {
        rej('Flow does not exist')
      }
    }),
    openPublicFlow: async ({commit}, {uid, flow}) => {
      try {
        commit('setAllFlows', {[uid]: flow});
        commit('setFlowUid', uid);
        flowStore.set('*', flow.nodes);
        commit('setNodes', flow.nodes);
      } catch (err) {
        throw new Error(err)
      }
    },
    loadFlow ({commit}, {flow, nodes}) {
      const uid = generateUid();
      commit('setFlow', {uid, flow})
      flowStore.set('*', nodes);
      commit('addRecentFlow', uid);
      commit('setFlowUid', uid)
    },
    getAllFlows: ({state, commit, rootState}) => new Promise((res, rej) => {
      if (!state.demo) {
        getAllFlows(rootState.auth.uid).then((flows) => {
          commit('setAllFlows', flows);
          res()
        }).catch(rej)
      } else {
        commit('setAllFlows', {});
      }
    }),
    init ({state, commit}) {
      return new Promise((res) => {
        commit('setNodes', flowStore.get());

        flowStore.onChange((nodes) => {
          commit('setNodes', nodes);
        });
        res(flowStore);
        state.initialized = true;
      });
    },
    getAllNodes: ({dispatch}, uid) => new Promise((res, rej) => {
      getAllNodes(uid).then((nodes) => {
        flowStore.set('*', nodes);
        dispatch('init').then(res);
      }).catch(rej)
    }),
    newFlow: ({state, commit, dispatch, rootState}, {name, x, y}) => new Promise((res, rej) => {
      const newFlow = cloneDeep(flow);
      const newNodeUid = generateUid();
      newFlow.name = name;
      newFlow.created = Date.now();
      newFlow.updated = Date.now();
      newFlow.owner = rootState.auth.uid;
      newFlow.start = newNodeUid;
      
      if (!state.demo) {
        createFlow(newFlow).then((docRef) => {
          const uid = docRef.id
          const eventUid = generateUid();
          const newFirstNode = cloneDeep(first_node);
          const newEvent = cloneDeep(event);
          newEvent.name = "ACTION 1"
          newFirstNode.meta.coords = ['top left', y, x];
          newFirstNode.on[eventUid] = newEvent;
          commit('setFlow', {uid, flow: newFlow});
          commit('setFlowUid', uid);
          flowStore.set('*', {[newNodeUid]: newFirstNode});
          dispatch('init').then(() => res(uid));
          updateNode(uid, newNodeUid, newFirstNode)
        }).catch(() => {
          rej();
        });
      } else {
        if (state.flowUid) {
          const uid = state.flowUid;
          const nodes =  flowStore.get();
          commit('setNodeCache', {uid, nodes});
        }
        
        const uid = generateUid();
        const eventUid = generateUid();
        const newFirstNode = cloneDeep(first_node);
        const newEvent = cloneDeep(event);
        newEvent.name = "ACTION 1"
        newFirstNode.meta.coords = ['top left', y, x];
        newFirstNode.on[eventUid] = newEvent;
        commit('setFlow', {uid, flow: newFlow});
        commit('setFlowUid', uid);
        flowStore.set('*', {[newNodeUid]: newFirstNode});
        dispatch('init').then(() => res(uid));
      }
      commit('clearHistory');
      commit('clearFuture');
    }),
    saveFlowAs: ({commit, state, rootState}, name) => new Promise((res, rej) => {
      let newFlow = cloneDeep(flow);
      newFlow.name = name;
      newFlow.created = Date.now();
      newFlow.updated = Date.now();
      newFlow.owner = rootState.auth.uid;

      createFlow(newFlow).then((docRef) => {
        const uid = docRef.id;
        commit('setFlow', {uid, flow: newFlow});
        commit('setFlowUid', uid);
        let data = flowStore.get();

        if (!state.demo) {
          updateNodes(state.flowUid, data).then(res).catch(rej)
        }
      }).catch(rej)
    }),
    createNode: ({state, commit, getters, rootState}, {x, y, key, uid}) => new Promise((res) => {
      commit('pushHistory');
      let newNodeUid = generateUid();
      commit('setCursorState', (uid ? 'drawing' : 'default'), { root: true })
      state.newNodeUid = newNodeUid;
      state.prevNodeUid = uid;
      state.prevEventKey = key
      state.newNodeUid = newNodeUid;
      let newNode = cloneDeep(node);
      newNode.meta.coords = ['top left', y - rootState.offset.top + rootState.scroll.top, x + rootState.scroll.left - rootState.offset.left];

      flowStore.batch((flowStore) => {
        if (state.prevNodeUid) {
          flowStore.set(`${state.prevNodeUid}.on.${key}.next`, state.newNodeUid);
          flowStore.set(`${state.prevNodeUid}.on.${key}.meta.coords`, ['top left', y - rootState.offset.top + rootState.scroll.top, x + rootState.scroll.left - rootState.offset.left]);
        } else {
          newNode.meta.defaultName = 'Node ' + getters.nodeCount;
          newNode.meta.viewState = 'default';

          if (!state.demo) {
            createNode(state.flowUid, state.newNodeUid, newNode)
          }
        }
        
        flowStore.set(state.newNodeUid, newNode);

        flowStore.done();
        res()
      }) 
    }),
    updateNode: ({state}, {ref, updates, del}) =>  new Promise((res) => {
      let nodeUid = ref.split('.').shift();

      if (updates) {
        flowStore.set(ref, updates);
        let updatedNode = flowStore.get(nodeUid);

        if (!state.demo) {
          updateNode(state.flowUid, nodeUid, updatedNode)
        }

        return res()
      }

      if (del) {
        flowStore.delete(ref);
        let updatedNode = flowStore.get(nodeUid);

        if (!state.demo) {
          updateNode(state.flowUid, nodeUid, updatedNode);
        }

        return res()
      }
    }),
    drawing: ({commit, state, rootState}) => new Promise((res) => {
      commit('setSelected', [], {root: true});
      flowStore.set(`${state.newNodeUid}.meta.coords.1`, rootState.mouseCurrent.y + rootState.scroll.top - rootState.offset.top - 12);
      flowStore.set(`${state.newNodeUid}.meta.coords.2`, rootState.mouseCurrent.x + rootState.scroll.left - rootState.offset.left);
      res();
    }),
    dragging: ({rootState, rootGetters}) => new Promise((res) => {
      let diffX = rootState.mouseCurrent.x - rootState.mouseLastFrame.x;
      let diffY = rootState.mouseCurrent.y - rootState.mouseLastFrame.y;
      const uids = rootGetters.selectedUids.map((uid) => {
        let {meta: {coords}, on} = flowStore.get(uid);
        coords[1] = coords[1] + diffY;
        coords[2] = coords[2] + diffX;    
        on = Object.keys(on).reduce((on, key) => {
          if ('coords' in on[key].meta) {
            on[key].meta.coords[1] += diffY;
            on[key].meta.coords[2] += diffX;
          }
          return on
        }, on)
        flowStore.set(`${uid}.meta.coords`, coords);
        flowStore.set(`${uid}.on`, on);
      });
      res(uids)
    }),
    deleteNodes: ({state, rootGetters, getters, commit}) => new Promise((res) => {
      commit('pushHistory');
      const {selectedUids} = rootGetters;
      flowStore.batch((flowStore) => {
        let deleted = selectedUids.map((uid) => {
          flowStore.findWhere('next', uid).map((ref) => {
            flowStore.set(ref, null)
          });
          if (uid !== getters.flow.start) {
            return flowStore.delete(uid);
          }
          return
        });        
        flowStore.done();
        res(deleted);
        return deleted;
      });

      if (!state.demo) {
        deleteNodes(state.flowUid, selectedUids)
      }
    }),
    updateAnchorCoords ({state, rootState}, {uid, on}) {
      on = Object.keys(on).map((key) => {
        const event = on[key];
        const $node = document.getElementById(`${uid}_event_anchor${key}`);
        if ($node) {
          let {x, y, width, height} = $node.getBoundingClientRect().toJSON();
          x = x + rootState.scroll.left + - rootState.offset.left + width/2;
          y = y + rootState.scroll.top + - rootState.offset.top + height/2;
          event.meta.coords = ['top left', y, x]
        }
        return event;
      });
      flowStore.set(`${uid}.on`, on);
      const vnode = flowStore.get(uid);

      if (!state.demo) {
        updateNode(state.flowUid, uid, vnode)
      }
    },
    dragEnd: ({dispatch, state, getters, rootState, rootGetters}) => new Promise(res => {
      const {x, y} = rootState.mouseCurrent
      const overNodes = getters.notSelectedRects.filter((rect) => isOver(rect, x, y));

      if (overNodes && overNodes.length) {
        flowStore.batch(flowStore => {
          let on = rootGetters.selectedUids.reduce((accumulator, uid) => {
            let on = flowStore.get(`${uid}.on`);
            accumulator = {...accumulator, ...on};
            return accumulator
          }, []);

          let {uid} = overNodes.pop();
          let nodeEvents = flowStore.get(`${uid}.on`)
          on = {...nodeEvents, ...on}
          on = Object.keys(on).reduce((on, key, i) => {
            if (on[key].next !== uid) {
              return on
            } else {
              on[key].next = null
            }
            
            on[key].meta.sort = i
            return on;
          }, on);

          flowStore.set(`${uid}.on`, on);
          
          rootGetters.selectedUids.map((deletedUid) => {
            let updated = flowStore.findWhere('next', deletedUid).map((ref) => {
              if (!ref.includes(uid)) {
                flowStore.set(ref, uid)
              }
              return ref
            });

            let uidsTopUpdate = updated.map((ref) => ref.split('.').shift());
            const toUpdate = uidsTopUpdate.reduce((accumulator, uid) => {
              accumulator[uid] = flowStore.get(uid);
              return accumulator;
            }, {});

            if (!state.demo) {
              updateNodes(state.flowUid, toUpdate)
            }
          });
          dispatch('deleteNodes');
          flowStore.done();
          res({type: 'merger', uid, on}) 
        });
      } else {
        const toUpdate = rootGetters.selectedUids.reduce((accumulator, uid) => {
          accumulator[uid] = flowStore.get(uid);
          return accumulator;
        }, {});

        if (!state.demo) {
          updateNodes(state.flowUid, toUpdate)
        }

        res('dragEnd')
      }
    }),
    drawEnd: ({state, getters, rootState}) => new Promise(res => {
      const {x, y} = rootState.mouseCurrent
      const overNodes = getters.notSelectedRects.filter((rect) => isOver(rect, x, y));
      if (overNodes && overNodes.length) {
        const next = overNodes.pop();
        if (next.uid === state.prevNodeUid) {
          flowStore.delete(state.newNodeUid);
          flowStore.set(`${state.prevNodeUid}.on.${state.prevEventKey}.next`, null);
          state.newNodeUid = null;
          state.prevNodeUid = null;
          state.prevEventKey = null;
          return res();
        } else {
          flowStore.batch(flowStore => {
            flowStore.set(`${state.prevNodeUid}.on.${state.prevEventKey}.next`, next.uid);
            flowStore.delete(state.newNodeUid);

            if (!state.demo) {
              updateNode(state.flowUid, state.prevNodeUid, flowStore.get(state.prevNodeUid));
            }

            state.newNodeUid = null;
            state.prevNodeUid = null;
            state.prevEventKey = null;
            flowStore.done();
          })
        }
      } else {
        flowStore.batch(flowStore => {
          flowStore.set(`${state.newNodeUid}.meta.defaultName`, 'Node ' + getters.nodeCount);
          flowStore.set(`${state.newNodeUid}.meta.viewState`, 'default');

          if (!state.demo) {
            createNode(state.flowUid, state.newNodeUid, flowStore.get(state.newNodeUid));
            updateNode(state.flowUid, state.prevNodeUid, flowStore.get(state.prevNodeUid));
          }

          flowStore.done();
        });

        state.newNodeUid = null;
        state.prevNodeUid = null;
        state.prevEventKey = null;
      }
      res()
    })
  }
}